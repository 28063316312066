// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let isActive = await store
        .getState()
        .blockchain.smartContract.methods.isActive()
        .call();
      let isWhitelistActive = await store
        .getState()
        .blockchain.smartContract.methods.isWhitelistSaleActive()
        .call();
      let wlWalletLimit =  await store
        .getState()
        .blockchain.smartContract.methods.whitelistWalletLimitation()
        .call();
      let publicWalletLimit =  await store
        .getState()
        .blockchain.smartContract.methods.publicWalletLimitation()
        .call();
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      console.log(totalSupply, isActive, isWhitelistActive);


      dispatch(
        fetchDataSuccess({
          totalSupply,
          isActive,
          isWhitelistActive,
          wlWalletLimit,
          publicWalletLimit,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

const initialState = {
  loading: false,
  totalSupply: 0,
  isActive: false,
  isWhitelistActive: false,
  wlWalletLimit: 2,
  publicWalletLimit: 1,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        isActive: action.payload.isActive,
        isWhitelistActive: action.payload.isWhitelistActive,
        wlWalletLimit: action.payload.wlWalletLimit,
        publicWalletLimit: action.payload.publicWalletLimit,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
